.MuiOutlinedInput-input{
    /* padding: 18.5px 14px !important; */
}
label{
    max-width: 100%;
    margin-top: -8px;
    margin-bottom: 5px;
}
.PrivateNotchedOutline-root-158{
    top:-13px;
}
.mb-16{
    margin-bottom: 1.6rem !important;
}
.login-h6 {
    align-self: center;
    margin: 10px;
}
.login-h66 {
    align-self: center;
    margin: 18px;
}

.login-div {
    padding: 1.8rem;
}

.sub-div {
    border-width: 2px;
    border-radius: 25px;
    border-color: #990000;
    width: 35%;
    height: 40%;
    align-self: center;
    text-align: center;
    margin: 20px;
}