@import-normalize;

@import 'tailwind-base.css';

@font-face {
	font-family: 'Titillium Web';
	font-size:12px !important;
    font-style: normal;
    font-weight: 200;
    src: local('Titillium WebThin'), local('TitilliumWeb-Thin'), url('http://themes.googleusercontent.com/static/fonts/titilliumweb/v1/anMUvcNT0H1YN4FII8wpr3XqdloEk0Ij5qwGW4qO9RM.woff') format('woff');
  }

body.no-animate *,
body.no-animate *::before,
body.no-animate *::after {
	transition: none !important;
	animation: none !important;
}

html {
    font-size: 62.5%;
    font-family: 'Titillium Web', sans-serif !important;
    background-color: #121212;
}
.MuiButton-label{
    font-family: 'Titillium Web', sans-serif !important;
}
.MuiOutlinedInput-input{
	font-family: 'Titillium Web', sans-serif !important;
	padding: 10px !important;
}
body {
    font-size: 13px;
    font-family: 'Titillium Web', sans-serif !important;
    line-height: 1.4;
}

html, body, #root {
    width: 100%;
    height: 100%;
    overflow: hidden;
    position: relative;
    margin: 0;
    font-family: 'Titillium Web', sans-serif !important;
}

h1, .h1 {
    font-size: 24px;
    font-family: 'Titillium Web', sans-serif !important;
}

h2, .h2 {
    font-size: 15px !important;
    font-family: 'Titillium Web', sans-serif !important;
}

h3, .h3 {
    font-size: 16px;
    font-family: 'Titillium Web', sans-serif !important;
}

h4, .h4 {
    font-size: 15px !important;
    font-family: 'Titillium Web', sans-serif !important;
}

h5, .h5 {
    font-size: 13px !important;
    font-family: 'Titillium Web', sans-serif !important;
}

h6, .h6 {
    font-size: 13px;
    font-family: 'Titillium Web', sans-serif !important;
}

.ps > .ps__rail-y,
.ps > .ps__rail-x {
	z-index: 99;
}

a[role='button'] {
	color: black;
	text-decoration: none;
}

[role='tooltip'] {
	z-index: 999;
}
.MuiList-root-310 {
	background: white;
}
.MuiIconButton-root-345 {
	color: black;
}
.MuiPaper-rounded {
	border-radius: 18px !important;
	box-shadow: none;
	/* margin-top: -10px !important; */
	/* margin-top: 35px; */
}
.MuiAppBar-colorPrimary-233 {
	background-color: white;
}
.makeStyles-content-224 {
	background-color: white;
}
/* .MuiIconButton-label .MuiTypography-colorInherit{
	color: inherit !important;
} */

.MuiTableCell-root{
	/* text-align: left !important; */
}
.MuiTableCell-sizeSmall{
	/* padding:0 7px !important; */
}
.MuiPickersDay-daySelected .MuiIconButton-label .MuiTypography-colorInherit{
color:white !important;
}
/* Medium Devices, Desktops Only */
@media only screen and (min-width: 992px) {
	::-webkit-scrollbar {
		width: 12px;
		height: 12px;
		background-color: rgba(0, 0, 0, 0);
	}

	::-webkit-scrollbar:hover {
		width: 12px;
		height: 12px;
		background-color: rgba(0, 0, 0, 0.06);
	}

	::-webkit-scrollbar-thumb {
		border: 2px solid transparent;
		box-shadow: inset 0 0 0 20px rgba(0, 0, 0, 0.24);
		border-radius: 20px;
	}

	::-webkit-scrollbar-thumb:active {
		box-shadow: inset 0 0 0 20px rgba(0, 0, 0, 0.37);
		border-radius: 20px;
	}
}

form label {
	z-index: 99;
}

@keyframes autofill {
	0%,
	100% {
		background: transparent;
	}
}
input:-webkit-autofill {
	animation-delay: 1s;
	animation-name: autofill !important;
	animation-fill-mode: both;
	-webkit-box-shadow: 0 0 0 100px transparent inset !important;
}

@import 'print.css';

@import 'tables.css';

@import 'prism.css';

@import 'tailwind.css';

.makeStyles-button-193 {
	display: none !important;
}
.makeStyles-button-305 {
	display: none !important;
}
.makeStyles-avatar-596 {
	top: 4px !important;
	left: 17% !important;
}

.makeStyles-button-275 {
	display: none !important;
}
.MuiIconButton-root-126 {
	display: block;
}
.Toastify__toast-container--top-center {
	top: 4em;
	left: 50%;
	margin-left: -160px;
}
.Toastify__toast-container {
	z-index: 99999999;
	position: fixed;
	padding: 4px;
	width: 320px;
	box-sizing: border-box;
	color: white;
}

.Toastify__toast-body {
	margin: auto 0;
	flex: 1 1;
	color: white;
	font-size: 16px;
}
.Toastify__close-button--default {
	color: white;
	opacity: 0.5;
}
.Toastify__toast--default {
	/* background: #129de8; */
	color: #aaa;
	/* box-shadow: 1px 1px 1px 1px #129de8; */
}

.Toastify__progress-bar--default {
	background: white;
}
@media only screen and (max-width: 1150px) {
	.mediaTable {
		margin-top: 16% !important;
		width: 100% !important;
	}
}
.makeStyles-root-128 .makeStyles-content-5 .ps {
	background: white !important;
}
.MuiDropzoneArea-root {
	background-color: #edeffa !important;
	color: #384aac !important;
	border-color: #384aac;
}
.MuiOutlinedInput-notchedOutline{
	border-color:#384aac ;
}
.MuiOutlinedInput-notchedOutline:hover{
	border-color:#384aac !important;
}
.MuiDropzoneArea-icon {
	display: none;
}
.MuiDropzoneArea-text {
	margin-top: 100px;
}
.MuiDropzoneSnackbar-successAlert {
	display: none;
}
.MuiDropzoneSnackbar-warningAlert {
	display: none;
}
.MuiDropzoneSnackbar-errorAlert {
	display: none;
}
.MuiDropzoneSnackbar-message {
	display: none;
}
.MuiDropzoneSnackbar-infoAlert {
	display: none;
}
.MuiDropzonePreviewList-removeButton {
	display: none;
}

.MuiDropzoneArea-textContainer > p {
	display: none;
}
.MuiDropzoneArea-textContainer:after {
	content: 'Or drag file in here';
	font-family: 'Titillium Web Light' !important;
	font-size: 18px;
}
.MuiDropzoneArea-textContainer {
	margin-top: 92px !important;
	font-size: 19px !important;
}
.uploadFile {
	margin-top: -30% !important;
	padding: 13px 75px 13px 75px;
}
@media only screen and (max-width: 1044px) {
	.uploadFile {
		margin-top: -38% !important;
	}
}
@media only screen and (max-width: 910px) {
	.uploadFile {
		margin-top: -41% !important;
	}
}
@media only screen and (max-width: 700px) and (min-width: 890px) {
	.uploadFile {
		margin-top: -57% !important;
	}
}
@media only screen and (max-width: 650px) {
	.uploadFile {
		margin-top: -76% !important;
	}
}
.makeStyles-navigation-268 .list-item:hover {
	color: #000 !important;
	/* background-color: #aaa !important; */
}
.makeStyles-root-275:hover {
	/* color: #384AAC !important; */
}
.makeStyles-navigation-268 .list-item:focus:not(.active) {
	color: #384aac !important;
	/* background-color: #fff !important; */
}
.makeStyles-root-265 .logo-icon {
	/* width: 25% !important; */
}
.open > div > div > div > a {
	outline: none;
	outline-color: white;
	border: none;
}
.makeStyles-root-267 .makeStyles-content-224 .ps {
	padding-top: 12px !important;
}
.MuiList-padding-272 {
	padding-top: 20px !important;
}
.makeStyles-item-352.active {
	/* background-color: #EDEFFA !important; */
	/* color: #000 !important; */
}
.makeStyles-root-275.open {
	/* background-color: white !important; */
}
.makeStyles-folded-11 {
	/* background-color: #fff !important; */
}
.makeStyles-item-1376.active {
	border-radius: 0;
	width: auto;
	/* background-color: white;
    color: black !important; */
}
.collapse-children > div > div > a {
	border-radius: 0;
	width: auto;
	/* background-color: white;
    color: black !important; */
}
.whitespace-no-wrap > ul > li {
	/* background-color: white; */
	border-radius: 0;
	width: auto;
}
.whitespace-no-wrap > ul >li > div > span {
	/* background-color: white; */
	font-family: 'Titillium Web', sans-serif !important;
	font-size: 13px !important;
	font-weight: 500;
}
/* .collapse-children{
    font-size: 13px;
} */
ul > div > div > div > a > div > span {
	/* display: list-item;
    list-style: disc; */
	margin-left: 15px !important;
	font-family: 'Titillium Web', sans-serif !important;
	font-size: 13px !important;
}
/* ul > div > div > div > a{
    list-style: decimal-leading-zero;
} */
/* .whitespace-no-wrap > ul > div > div >div>a:default {
    background-color: #EDEFFA !important;
} */
.whitespace-no-wrap > ul > div > div > div {
	background-color: #fff !important;
	/* list-style: decimal-leading-zero !important; */
}
/* .whitespace-no-wrap > ul > div >div >div >a:focus {
    background-color: #EDEFFA !important;
} */
.whitespace-no-wrap > ul > div > div > div > a:active {
	/* background-color: #EDEFFA !important; */
}
.active {
	background-color: #edeffa !important;
	outline: none !important;
	border: none !important;
}
.whitespace-no-wrap > ul > li > div:hover {
	color: black !important;
}

.whitespace-no-wrap > ul > li > div:active {
	color: black !important;
	outline: none !important;
	border: none !important;
}
.list-item-text > span {
	color: black !important;
	/* background-color: #EDEFFA !important; */
}
/* .MuiAvatar-img-96{
    object-fit: contain;
    height: 25px;
    width: 25px;
} */
#fuse-toolbar{
    background-color: #fff !important;
}
.user-logo-photo{
    width: 20px;
    height: 20px;
    margin-top: 17px;
}
.user-photo{
    width: 20px;
    height: 20px;
    margin-right: 10px;
    margin-top: 15%;
    margin-left: 10px;
}
.logoforchatbotinheader{
    width: 6vh;
    height: 6vh;
    margin-right: 31px;
}
@media only screen and (max-width: 780px) {
	.logoforchatbotinheader{
		width: 7vh;
		height: 6vh;
	}
}
.Header-User{
    /* width: 100%; */
    /* font-size: 15px; */
    height: 25px;
    margin-top: 10px;
    margin-right: 10px;
    margin-left: -29px;
}
.headerChat{
    margin-top: -5%;
    /* margin-left: 75%; */
}
.headerList1{
    /* width: 80%; */
    margin-right: 10%;
    box-shadow: rgba(0, 0, 0, 0.2) 0px 2px 4px -1px, rgba(0, 0, 0, 0.14) 0px 4px 5px 0px, rgba(0, 0, 0, 0.12) 0px 1px 10px 0px;
    font-size: 12px;    
    border-radius: 5px;
}
.headerList{
    width: 80%;
    margin-right: 10%;
    margin-left: 15%;
    /* box-shadow: rgba(0, 0, 0, 0.2) 0px 2px 4px -1px, rgba(0, 0, 0, 0.14) 0px 4px 5px 0px, rgba(0, 0, 0, 0.12) 0px 1px 10px 0px; */
    font-size: 12px;    
    border-radius: 5px;
}
.content-header{
    display:flex;
    margin-top: 1%;
}
#fuse-toolbar{
    box-shadow: none;
}
.imgCheck{
    /* position: inherit;
    margin-top: -30%;
    margin-left: 69%; */
}
.Headerboder {
	border-bottom: 0.2px solid grey;
	margin-top: 33px;
}
.MuiButton-containedPrimary:hover {
	background: #293b96 !important;
}
.flexwidth {
	width: 25%;
}
@media only screen and (max-width: 1054px) {
	.flexwidth {
		width: 30%;
	}
}
@media only screen and (max-width: 940px) {
	.flexwidth {
		width: 38%;
	}
}
@media only screen and (max-width: 800px) {
	.flexwidth {
		width: 44%;
	}
}
@media only screen and (max-width: 736px) {
	.flexwidth {
		width: 52%;
	}
}
@media only screen and (max-width: 670px) {
	.flexwidth {
		width: 70%;
	}
}
#fuse-footer{
    /* background-color: #384AAC !important; */
    background-color: #fff !important;
    color: black;
    font-size: 13px !important;
    height: 30px;
    font-family: 'Titillium Web', sans-serif !important;
}
@media only screen and (max-width: 780px) {
	#fuse-footer{
	height: 40px;
	}
}
#typo {
	font-size: 13px !important;
	font-family: 'Titillium Web', sans-serif !important;
}
.items-center-new {
	margin-left: 25%;
	align-items: initial !important;
	text-align: center;
	margin-top: 0.5%;
}
@media only screen and (max-width: 780px) {
	.items-center-new{
		margin-left: 0%;
	}
}
.ps {
	position: inherit;
	overflow: hidden;
}
.MuiTableCell-sizeSmall {
	font-size: 13px !important;
	
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

/* .MuiPaper-rounded{
    margin-top: 10px !important;
} */
/* .makeStyles-navbar-8:hover {
	width: 300px !important;
}
.makeStyles-navbar-8:active {
	width: 300px !important;
} */
.ps--active-y {
	position: inherit !important;
	/* font-family: 'Titillium Web', sans-serif !important;
	font-size: 12px !important; */
}

.HeaderListCheckBorder {
	margin-top: 30px;
}
.AdddateGrid {
	width: 100%;
	height: 38px;
	border: 1px solid #384aac;
	border-radius: 5px;
}
.MuiTablePagination-caption
{
	font-size: 13px;
	font-family: 'Titillium Web Light';
}
.MuiTable-root{
	border-collapse:inherit
}
.MuiSelect-selectMenu{
	font-size: 13px;
}
.WithStyles\(ForwardRef\(TableCell\)\)-body-987
{
	font-size: 13px;
}
.makeStyles-paper-664 {
    width: 100%;
    padding: 16px 32px 323px;
    background-color: #FFFFFF;
}

.makeStyles-paper-909 {
    width: 100%;
    padding: 16px 32px 541px;
    background-color: #FFFFFF;
}
.MuiButton-containedPrimary {
	background:#384AAC ;
}
.MuiTableCell-head {
	font-size: 13px !important;
}

.MuiButton-text:hover{
	background: none !important;
}
.PrivateSwitchBase-root-463{
	padding: 3px;
}

.MuiSwitch-thumb {
    width: 13px !important;
    height: 14px !important;
    box-shadow: 0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12);
    border-radius: 50%;
    background: white;
    margin-top: 10px !important;
    /* margin-left: 4px; */
}
.MuiSwitch-track
{
	background: #384aac !important;
}
.MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track {
	opacity: 1 !important; 
	padding: 7px !important;
}
.MuiSwitch-switchBase.Mui-disabled + .MuiSwitch-track {
	opacity: 0.3 !important; 
	padding: 10px !important;
}
