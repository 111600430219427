/**
Basic Table Styles
 */
.table-responsive {
    display: block;
    width: 100%;
    overflow-x: auto;
    font-size: 13px !important;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar;
}

table.simple {
    width: 100%;
    border: none;
    border-spacing: 0;
    text-align: left;
}

table.simple thead tr th {
    padding: 16px 8px;
    font-weight: 500;
    border-bottom: 1px solid rgba(0, 0, 0, 0.12);
    white-space: nowrap;
}

table.simple thead tr th:first-child {
    padding-left: 24px;
}

table.simple thead tr th:last-child {
    padding-right: 24px;
}

table.simple tbody tr td {
    padding: 16px 8px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}

table.simple tbody tr td:first-child {
    padding-left: 24px;
}

table.simple tbody tr td:last-child {
    padding-right: 24px;
}

table.simple tbody tr:last-child td {
    border-bottom: none;
}

table.simple.clickable tbody tr {
    cursor: pointer;
}

table.simple.clickable tbody tr:hover {
    background: rgba(0, 0, 0, 0.03);
}
.Toastify__toast-container--top-center{
    top: 4em;
    left: 50%;
    margin-left: -160px;
   }
.Toastify__toast-container{
    z-index: 99999999;
    position: fixed;
    padding: 4px;
    width: 320px;
    box-sizing: border-box;
    color: white;
}

.Toastify__toast-body {
  margin: auto 0;
  flex: 1 1;
  color: white;
  font-size: 16px;
}
.Toastify__close-button--default{
  color:white;
    opacity: 0.5;
}
.Toastify__toast--default {
  background: #129DE8;
  color: #aaa;
  box-shadow: 1px 1px 1px 1px #129DE8;
}

.Toastify__progress-bar--default{
  background: white;
}