.Toastify__toast-container{
    z-index: 99999999;
    position: fixed;
    padding: 4px;
    width: 320px;
    box-sizing: border-box;
    color: white;
    margin-top: 55px;
  }
  .Toastify__toast-container--top-center{
    top: 4em;
    left: 50%;
    margin-left: -160px;
   }
  .Toastify__toast-body {
  margin: auto 0;
  flex: 1 1;
  color: white;
  font-size: 16px;
  }
  .Toastify__close-button--default{
  color:white;
    opacity: 0.5;
  }
  .Toastify__toast--default {
  background: #293b96;
  color: #aaa;
  box-shadow: 1px 1px 1px 1px #293b96;
  }
   
  .Toastify__progress-bar--default{
  background: white;
  }

  .MuiAlert-standardError {
    color:#f44336;;
    padding: 0px;
    font-size: 13px;
    padding-left: 5px;
    height: 11px;
}
